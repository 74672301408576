.content-block-split-width.symmetrical-feature__carousel {
  display: grid;
  grid-gap: 0;
  grid-template-areas: 'title . ' 'body . ' 'image image' 'footer footer';
  grid-template-rows: [title] auto [body] auto [image] auto [footer] auto;
  grid-template-columns: [col1] 1fr;
  @include breakpoint($medium-up) {
    grid-template-columns: [col1] 1fr [col2] 1fr;
    padding: 0;
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__line,
        &__title,
        &__eyebrow,
        &__subtitle,
        &__body,
        &__cta,
        &__caption {
          padding: 10px 20px;
          @include breakpoint($landscape-up) {
            max-width: 55%;
            margin-#{$rdirection}: 17%;
            margin-#{$ldirection}: 15%;
          }
        }
        &__hero-image {
          @include swap_direction(padding, 20px 24px 20px 0);
          @include breakpoint($landscape-up) {
            padding: 20px 0;
          }
          image {
            width: 400px;
            height: 321px;
            object-fit: cover;
          }
        }
      }
    }
    &__1.content-block-split-width {
      &__title {
        padding-top: 20px;
        padding-bottom: 10px;
        grid-area: 1;
        @include breakpoint($landscape-up) {
          padding-top: 0;
          grid-area: 1;
        }
      }
      &__body {
        padding: 10px 20px 31px;
        grid-area: 2;
        @include breakpoint($landscape-up) {
          grid-area: 2;
          padding: 10px 20px;
        }
      }
      &__hero-image {
        grid-area: 3;
        @include breakpoint($landscape-up) {
          grid-area: 3/1;
          img {
            padding: 0;
            object-position: center $ldirection;
          }
        }
      }
      &__caption {
        grid-area: 4;
        @include breakpoint($landscape-up) {
          grid-area: 4/1;
        }
      }
    }
    &__2 {
      &.content-block-split-width {
        &__line,
        &__title,
        &__body,
        &__cta {
          @include breakpoint($landscape-up) {
            width: 55%;
            margin-#{$rdirection}: 15%;
            margin-#{$ldirection}: 17%;
          }
        }
        &__hero-image {
          grid-area: 5;
          @include breakpoint($landscape-up) {
            grid-area: 3/2;
          }
          img {
            @include breakpoint($landscape-up) {
              object-position: center $rdirection;
            }
          }
        }
      }
      .content-block__disclaimer {
        padding: 10px 20px;
        @include breakpoint($landscape-up) {
          width: 55%;
          margin-#{$rdirection}: 15%;
          margin-#{$ldirection}: 17%;
        }
      }
    }
  }
}
