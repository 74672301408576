.content-block-split-width {
  .mantle-media-asset {
    position: relative;
    height: 100%;
    video {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      #{$ldirection}: 0;
      &.lazyloaded {
        max-width: 100%;
        height: max-content;
        height: 100%;
        object-fit: cover;
        display: flex;
      }
    }
  }
}