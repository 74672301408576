.split-width-carousel {
  transition: all ease-in-out 0.25s;
  .content-block-split-width {
    margin-#{$ldirection}: 0;
    margin-#{$rdirection}: 0;
  }
  &.slick-center {
    transition: all ease-in-out 0.25s;
  }
  &__slide {
    &:focus {
      outline-color: transparent;
      &-visible {
        outline-color: $black;
      }
    }
    &.slick-active,
    &.slick-current {
      .content-block__controls {
        display: flex;
        transition: opacity ease-in-out 0.25s;
        opacity: 1;
      }
    }
    .split-width-carousel__media-container {
      grid-row: 1;
      @include breakpoint($medium-up) {
        grid-row: unset;
      }
    }
    .content-block {
      &__controls {
        opacity: 0;
        transition: opacity ease-in-out 0.25s;
        position: relative;
        justify-content: space-between;
        margin-top: 5%;
        margin-bottom: 20px;
        padding-#{$ldirection}: 20px;
        width: 109px;
        @include breakpoint($medium-up) {
          position: absolute;
          bottom: 70px;
        }
        .carousel-controls {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        &.column__1,
        &.column__2 {
          &.content-block-split-width {
            &__title,
            &__body,
            &__controls,
            &__eyebrow,
            &__subtitle {
              padding: 0 20px;
            }
            &__hero-image {
              padding: 0;
            }
          }
        }
        &.column__1 {
          margin-#{$ldirection}: 0;
          padding: 0 20px;
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: 17%;
            margin-#{$ldirection}: 7.5%;
          }
        }
        &.column__2 {
          margin-#{$ldirection}: 0;
          padding: 0 20px;
          @include breakpoint($landscape-up) {
            margin-#{$rdirection}: 7.5%;
            margin-#{$ldirection}: 17%;
          }
        }
        .slick {
          &-prev-button,
          &-next-button {
            height: 50px;
          }
          &-prev-button {
            justify-content: flex-start;
            opacity: 1;
          }
          &-next-button {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.mobile_image_on_top{
  .split-width-carousel__media-container {
    grid-row: 1;
    @include breakpoint($medium-up) {
      grid-row: unset;
    }
  }
}

.mobile_text_on_top{
  .split-width-carousel__media-container {
    grid-row: 2;
    @include breakpoint($medium-up) {
      grid-row: unset;
    }
  }
}
