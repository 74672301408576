.content-block-split-width.symmetrical-feature__single {
  grid-template-columns: [col1] 1fr;
  justify-content: flex-start;
  padding-#{$rdirection}: 0;
  @include breakpoint($medium-up) {
    grid-template-columns: [col1] 1fr [col2] calc(50% + 20px);
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__title,
        &__body,
        &__eyebrow,
        &__subtitle,
        &__cta {
          padding: 4px 20px;
        }
      }
    }
    &__1 {
      &.content-block-split-width {
        &__title,
        &__body,
        &__eyebrow,
        &__subtitle,
        &__cta {
          @include fluid-sizing(margin-#{$ldirection}, 0px, 115px, $medium-up, $desktop-site-width, 'width', false);
          @include fluid-sizing(margin-#{$rdirection}, 20px, 150px, $medium-up, $desktop-site-width, 'width', false);
        }
        &__title {
          padding-bottom: 8px;
          grid-row: 1;
          @include breakpoint($medium-up) {
            max-width: 425px;
            grid-row: 1;
            padding-#{$ldirection}: 0;
          }
        }
        &__body {
          @include swap_direction(padding, 10px 10px 0 20px);
          grid-row: 2;
          max-width: 100%;
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 10px 40px 10px 0);
            grid-row: 2;
          }
        }
        &__cta {
          @include swap_direction(padding, 10px 0 0 20px);
          grid-row: 3;
          max-width: 100%;
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 10px 40px 10px 0);
            grid-row: 3;
          }
        }
      }
    }
    &__2 {
      padding-top: 20px;
      grid-row: 5;
      @include breakpoint($medium-up) {
        padding-top: 0;
        grid-row: 1/5;
      }
      .content-block-split-width {
        &__title,
        &__body,
        &__eyebrow,
        &__subtitle,
        &__cta {
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 15.5%;
            margin-#{$ldirection}: 22%;
          }
        }
      }
    }
  }
}