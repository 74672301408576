.content-block-split-width.symmetrical-feature__content {
  display: grid;
  grid-gap: 0;
  grid-template-areas: "title . " "body . " "image image" "footer footer";
  grid-template-rows: [title] 0fr [body] 0fr [image] 0fr [footer] 0fr;
  grid-template-columns: [col1] 1fr;
  padding: 0 20px;
  @include breakpoint($medium-up) {
    grid-template-columns: [col1] 1fr [col2] 1fr;
    padding: 0 40px;
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__title {
          grid-row: 1;
          padding-bottom: 10px;
        }
        &__body {
          max-width: 100%;
          padding: 5px 0 25px;
          grid-row: 2;
        }
        &__hero-image {
          padding: 0;
          img {
            padding: 0;
            width: 100%;
            object-position: center;
            aspect-ratio: 4 / 5;
            .column-layout--reversed & {
              @include breakpoint($medium-up) {
                object-position: center $ldirection;
              }
            }
          }
        }
        &__caption {
          grid-row: 4;
          @include swap_direction(padding, 16px 20px 20px 0);
        }
      }
    }
    &__1.content-block-split-width {
      &__body {
        @include breakpoint($medium-up) {
          @include swap_direction(padding, 5px 40px 25px 0);
        }
      }
      &__hero-image {
        grid-row: 3;
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 20px;
          .column-layout--reversed & {
            padding-#{$ldirection}: 20px;
            padding-#{$rdirection}: 0;
          }
        }
      }
    }
    &__2.content-block-split-width {
      &__hero-image {
        grid-row: 5;
        @include breakpoint($medium-up) {
          grid-row: 3;
          padding-#{$ldirection}: 20px;
          .column-layout--reversed & {
            padding-#{$rdirection}: 20px;
            padding-#{$ldirection}: 0;
          }
        }
        img {
          object-position: center;
          @include breakpoint($medium-up) {
            object-position: center $rdirection;
          }
        }
      }
    }
  }
}
