.content-block__line.content-block-split-width {
  &__product--cta,
  &__cta {
    @include link;
    border-bottom: 0;
    padding-top: 10px;
    a {
      &.link {
        background: none;
        border: none;
        padding: 0;
        text-align: $ldirection;
        text-decoration: underline;
        text-underline-offset: 5px;
        @if $cr22 {
          text-transform: lowercase;
          &:first-letter {
            text-transform: uppercase;
          }
        } @else {
          text-transform: none;
        }
      }
    }
  }
}
