.content-block-split-width__has-overlay {
  position: relative;
  .content-block__overlay-text {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    overflow: hidden;
    z-index: 9;
    width: 100%;
    height: fit-content;
    .mantle-media-asset {
      grid-area: 1/-1;
    }
    .mantle-custom-text {
      grid-area: 1/-1;
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      z-index: 2;
      &.style--bare {
        color: $white;
        @include typestyle--h2;
      }
      p {
        margin-bottom: 0;
      }
    }
    &.overlay-align {
      &--left .mantle-custom-text {
        justify-content: flex-start;
      }
      &--right .mantle-custom-text {
        justify-content: flex-end;
      }
      &---horizontal-center .mantle-custom-text {
        justify-content: center;
      }
      &--vertical-center .mantle-custom-text {
        align-items: center;
      }
      &--top .mantle-custom-text {
        align-items: flex-start;
      }
      &--bottom .mantle-custom-text {
        align-items: flex-end;
      }
      ;
    }
  }
}