.content-block-split-width.asymmetrical-feature__single {
  &.flex-box-grid {
    display: grid;
    grid-template-columns: [col1] 1fr;
    @include breakpoint($medium-up) {
      grid-template-columns: [col1] 1fr [col2] calc(50% + 20px);
      column-gap: 0;
    }
    &.justify-start {
      .content-block__line {
        @include breakpoint($medium-up) {
          padding-top: 0;
        }
      }
    }
    .content-block {
      &__line,
      &__cta,
      &__disclaimer {
        @include x-padding(20px);
        @include fluid-sizing(margin-#{$rdirection}, 25px, 135px, $medium-up, $desktop-site-width, 'width', false);
        @include fluid-sizing(margin-#{$ldirection}, 0px, 38px, $medium-up, $extra-extra-large-up, 'width', false);
        @include fluid-sizing(margin-#{$ldirection}, 35px, 5px, $extra-extra-large-up, $desktop-site-width, 'width', false);
        max-width: 100%;
        @include breakpoint($medium-up) {
          @include x-padding(20px);
        }
      }
      &__cta {
        padding-top: 20px;
        padding-bottom: 40px;
        @include breakpoint($medium-up) {
          padding-top: 10px;
          padding-bottom: 15px;
        }
      }
      &__container {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        &.column {
          &__1,
          &__2 {
            width: 100%;
          }
          &__1 {
            @include x-padding(20px);
            grid-row: 1;
            @include breakpoint($medium-up) {
              @include x-padding(0);
            }
          }
          &__2 {
            grid-row: 2;
            padding-top: 30px;
            @include breakpoint($medium-up) {
              grid-row: 1;
              padding-top: 0;
            }
          }
        }
        .content-block__disclaimer--content {
          margin-bottom: 20px;
        }
      }
    }
    &.column-layout--reversed {
      .content-block {
        &__line,
        &__cta {
          @include x-padding(20px);
          @include breakpoint($medium-up) {
            padding-#{$ldirection}: 17%;
            padding-#{$rdirection}: 0;
          }
          &__container {
            &.column {
              &__1 {
                grid-row: 2;
                padding-top: 30px;
                @include breakpoint($medium-up) {
                  grid-row: 1;
                  padding-top: 0;
                }
              }
              &__2 {
                @include x-padding(20px);
                grid-row: 1;
                @include breakpoint($medium-up) {
                  @include x-padding(20);
                  grid-row: 2;
                }
              }
            }
          }
        }
      }
    }
  }
}
