.content-block-split-width {
  &--asymmetrical {
    @include fluid-sizing(padding-#{$ldirection}, 20px, 40px, $medium-up, $landscape-up, 'width', false);
    @include fluid-sizing(column-gap, 40px, 175px, $medium-up, $desktop-site-width, 'width', false);
    display: grid;
    grid-gap: 0;
    column-gap: 0;
    grid-template-areas: "title . " "body . " "cta . " "image image" "footer .";
    grid-template-rows: [title] auto [body] auto [cta] auto [image] auto [footer] auto;
    grid-template-columns: [col1] 1fr;
    padding-top: 0;
    padding-bottom: 0;
    @include breakpoint($medium-up) {
      grid-template-columns: [col1] 7fr [col2] 10fr;
    }
    &.column-layout--reversed {
      @include breakpoint($medium-up) {
        grid-template-columns: [col1] 10fr [col2] 7fr;
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 40px;
      }
    }
  }
}