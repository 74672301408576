.content-block-split-width.asymmetrical-feature__benefits {
  padding: 0 20px;
  @include fluid-sizing(padding-#{$ldirection} padding-#{$rdirection}, 20px, 115px, $medium-up, $desktop-site-width, 'width', false);
  @include breakpoint($medium-up) {
    grid-template-columns: [col1] 50% [col2] 50%;
    grid-template-rows: [title] 0fr [body] 0fr [cta] 0fr [image] 1fr [footer] 0fr;
    column-gap: 0;
  }
  .content-block-split-width {
    &__eyebrow,
    &__title,
    &__subtitle,
    &__body,
    &__cta {
      @include fluid-sizing(margin-#{$rdirection}, 35px, 135px, $medium-up, $desktop-site-width, 'width', false);
      @include fluid-sizing(margin-#{$ldirection}, 0px, 38px, $medium-up, $extra-extra-large-up, 'width', false);
      @include fluid-sizing(margin-#{$ldirection}, 35px, 45px, $extra-extra-large-up, $desktop-site-width, 'width', false);
      padding: 5px 0 0;
      max-width: 100%;
      @include breakpoint($desktop-site-width) {
        @include swap_direction(padding, 10px 0 0);
        margin-#{$ldirection}: 6px;
      }
    }
    &__title,
    &__cta,
    &__body {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 0;
      }
    }
    &__hero-image {
      img {
        @include fluid-sizing(width, 266px, 562px, $iphonex-width);
        aspect-ratio: 4 / 5;
      }
    }
    &__title {
      padding-top: 0;
    }
    &__cta {
      @include fluid-sizing(margin-bottom, 40px, 75px, $medium-up, $desktop-site-width);
    }
  }
  .column {
    &__1 {
      grid-column: 1;
      &.content-block-split-width {
        &__title {
          grid-row: 1;
        }
        &__body {
          grid-row: 2;
          padding-top: 10px;
          padding-bottom: 10px;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
        }
        &__cta {
          grid-row: 3;
        }
        &__hero-image {
          display: flex;
          grid-row: 4;
          justify-content: flex-start;
          @include breakpoint($medium-up) {
            justify-content: flex-end;
            margin-top: 0;
          }
        }
      }
    }
    &__2 {
      grid-column: 1;
      @include breakpoint($medium-up) {
        grid-column: 2;
      }
      &.content-block-split-width {
        &__hero-image {
          display: flex;
          justify-content: flex-end;
          grid-row: 5;
          @include breakpoint($medium-up) {
            justify-content: flex-start;
            grid-row: 1/5;
          }
          img {
            object-position: top $ldirection;
          }
        }
      }
    }
  }
  &.column-layout--reversed {
    .column {
      &__1 {
        grid-column: 2;
        &.content-block__line {
          @include breakpoint($medium-up) {
            margin-#{$rdirection}: 80px;
          }
        }
        &.content-block-split-width {
          &__hero-image {
            @include breakpoint($medium-up) {
              justify-content: flex-start;
            }
          }
        }
      }
      &__2 {
        grid-column: 1;
        &.content-block-split-width {
          &__hero-image {
            @include breakpoint($medium-up) {
              justify-content: flex-end;
            }
            img {
              object-position: top $rdirection;
            }
          }
        }
      }
    }
  }
}
