.content-block-split-width {
  &__eyebrow {
    .style--bare,
    .format--default {
      @include typestyle--b2;
      text-transform: uppercase;
      color: $color-neutral-gray;
    }
  }
  &__title {
    padding-top: 0;
    word-break: break-word;
    @include breakpoint($med-small-up) {
      word-break: keep-all;
    }
    .style--bare,
    .format--default {
      @include typestyle--h3;
      line-height: normal;
      color: $color-neutral-dark-gray;
      text-transform: uppercase;
      @include breakpoint($tablet-only){
        font-size: 32px;
        letter-spacing: -0.03em;
        font-weight: 600;
      }
    }
  }
  &__subtitle {
    .style--bare,
    .format--default {
      @include typestyle--h6;
      color: $color-neutral-dark-gray;
    }
  }
  &__body {
    max-width: 100%;
    @include breakpoint($medium-up) {
      max-width: 60%;
    }
    .style--bare,
    .format--default {
      @include typestyle--b1;
      color: $color-neutral-gray;
      @include breakpoint($tablet-only) {
        font-size: 14px;
      }
    }
  }
  &__cta {
    .style--bare,
    .format--default {
      @include link;
      @include typestyle--h8;
      color: $color-neutral-dark-gray;
    }
    a.link {
      @include typestyle--h8;
      color: $color-neutral-dark-gray;
    }
  }
  &__caption {
    .style--bare,
    .format--default {
      color: $color-neutral-gray;
      @include typestyle--b1;
    }
  }
  &__product {
    &--title {
      .style--bare,
      .format--default {
        @include typestyle--h9;
        color: $color-neutral-dark-gray;
        font-weight:400;
      }
    }
    &--description,
    &--pricing {
      .style--bare,
      .format--default {
        @include typestyle--b1;
        color: $color-neutral-gray;
      }
    }
    &--cta {
      a.link {
        @include typestyle--b1;
        color: $color-neutral-dark-gray;
        transition: color $transition-duration;
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
        &:hover {
          @if $cr22 {
            color: $color-neutral-dark-gray;
          } @else {
            color: $color-green;
            transition: color $transition-duration;
          }
        }
      }
    }
  }
}
