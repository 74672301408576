@import '../../../../scss/theme-bootstrap';
@import './sass/modules/modules';
@import './sass/features/features';
@import './sass/blocks/blocks';
.content-block-split-width {
  margin-#{$rdirection}: auto;
  margin-#{$ldirection}: auto;
  position: relative;
  &.wide-copy {
    .column__1,
    .column__2 {
      &.content-block-split-width {
        &__title,
        &__body,
        &__caption,
        &__cta {
          @include breakpoint($landscape-up) {
            margin: 0 auto;
            max-width: 92%;
            width: auto;
          }
        }
        &__title .mantle-custom-text {
          font-size: 28px;
          letter-spacing: -0.03em;
          @include breakpoint($landscape-up) {
            font-size: 40px;
          }
        }
        &__body .mantle-custom-text,
        &__cta,
        &__cta a {
          font-size: 14px;
          @include breakpoint($landscape-up) {
            font-size: 15px;
          }
        }
        &__caption .mantle-custom-text {
          font-size: 12px;
        }
        &__carousel.content-block__controls {
          @include breakpoint($landscape-up) {
            margin-#{$ldirection}: 6%;
          }
        }
      }
    }
    &.column-layout--reversed {
      .column__1,
      .column__2 {
        &.content-block-split-width__carousel.content-block__controls {
          @include breakpoint($landscape-up) {
            margin-#{$ldirection}: 3%;
          }
        }
      }
      .column__2 {
        @include breakpoint($medium-up) {
          grid-row: 1;
        }
      }
    }
  }
}
