.content-block-split-width.symmetrical-feature__product {
  display: grid;
  grid-template-rows: [image] auto [product-title] 0fr [product-description] 0fr [product-pricing] 0fr [product-cta] 0fr;
  .content-block__line {
    padding: 4px 0 0;
  }
  .column {
    &__1,
    &__2 {
      &.content-block-split-width {
        &__product--title,
        &__caption {
          padding-top: 16px;
        }
        &__product {
          &--title,
          &--description,
          &--pricing,
          &--cta {
            @include x-padding(20px);
            @include breakpoint($medium-up) {
              padding-#{$rdirection}: 17%;
              padding-#{$ldirection}: 0;
            }
          }
        }
        &__product--image {
          aspect-ratio: 4 / 5;
          .mantle-media-asset {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    &__1 {
      @include breakpoint($medium-up) {
        flex-direction: row;
      }
      &.content-block-split-width {
        &__product {
          grid-row: 2;
          @include breakpoint($medium-up) {
            grid-row: 1;
          }
          &--title {
            grid-row: 3;
            @include breakpoint($medium-up) {
              grid-row: 2;
            }
          }
          &--description {
            grid-row: 4;
            @include breakpoint($medium-up) {
              padding-top: 0;
              padding-#{$rdirection}: 20px;
              grid-row: 3;
            }
          }
          &--pricing {
            grid-row: 5;
            @include breakpoint($medium-up) {
              grid-row: 4;
            }
          }
          &--cta {
            grid-row: 6;
            padding-top: 8px;
            @include breakpoint($medium-up) {
              grid-row: 5;
            }
          }
          &--image {
            @include breakpoint($medium-up) {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    &__2 {
      grid-row: 1;
      @include breakpoint($medium-up) {
        grid-row: 1;
      }
      &.content-block-split-width {
        &__hero-image {
          aspect-ratio: 4 / 5;
          img {
            object-fit: contain;
            width: 100%;
          }
        }
        &__caption {
          display: none;
          @include breakpoint($medium-up) {
            padding-#{$rdirection}: 40px;
            display: block;
            grid-row: 2/5;
          }
          .style--bare {
            border-bottom: none;
          }
        }
      }
    }
  }
}