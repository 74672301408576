.content-block-split-width {
  &--symmetrical {
    @include fluid-sizing(padding-left padding-right, 20px, 40px, $medium-up, $landscape-up, 'width', false);
    display: grid;
    grid-gap: 0;
    grid-template-areas: 'title . ' 'body . ' 'image image' 'footer footer';
    grid-template-rows: [title] auto [body] auto [image] auto [footer] auto;
    grid-template-columns: [col1] 1fr;
    @include breakpoint($medium-up) {
      grid-template-columns: repeat(2, 1fr);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}